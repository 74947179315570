<template>
  <div>
    <div class="holder">
      <h5 class="financing-text">Rent financing</h5>
      <!-- <button data-toggle="modal" data-target="#rentsuccess">OPEN</button> -->

      <div v-if="hideDiv">
        <PageLoader></PageLoader>
      </div>
      <div v-else class="parent row">
        <div class="col-md-6">
          <div class="left-side">
            <h6>Rent Financing</h6>
            <p>
              Lack of immediate cash should not take your peace or deny you
              living in your desired home. With rent financing, we bridge the
              gap effortlessly for you. Instead of paying a year’s rent upfront,
              you can pay weekly, monthly, biannually or on the go.
            </p>
          </div>
        </div>
        <div class="col-md-6">
          <div>
            <form>
              <div class="form-group amountNaira">
                <label for="annualrent">Annual rent</label>
                <span class="naira">₦</span>
                <input
                  disabled
                  type="text"
                  onfocus="(this.type='text')"
                  class="form-control"
                  id="annualrent"
                  name="annualrent"
                  v-model="amount2"
                  placeholder="Annual rent"
                />
              </div>
              <div class="form-group">
                <div>
                  <label for="mode" class="mode-label"
                    >Select mode of payment</label
                  >
                  <div class="row" id="mode">
                    <div
                      class="col-md-4"
                      @click="totalpayment = mode.sums"
                      v-for="(mode, index) in rent.tenantmodeofpayments"
                      :key="index"
                    >
                      <button
                        :disabled="
                          rent.tenantmodeofpaymentid == mode.id ||
                            mode.id == '4'
                        "
                        @click.prevent="
                          activemode = index;
                          active(index, mode);
                        "
                        class="rate btn"
                        :class="[activemode === index ? 'activeMode' : '']"
                      >
                        <p>{{ mode.type }}</p>
                        <h6>
                          <!-- ₦ -->
                          &#8358;{{ Number(mode.price).toLocaleString() }}
                        </h6>
                      </button>
                    </div>
                    <!-- <div class="col-md-4">
                        <div class="rate">
                          <p>Quaterly</p>
                          <h6>₦900,000</h6>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="rate">
                          <p>Monthly</p>
                          <h6>₦900,000</h6>
                        </div>
                      </div> -->
                  </div>
                </div>
              </div>
              <div class="form-group" v-if="paymode && mode != ''">
                <div class="breakdown">
                  <h4>Breakdown</h4>
                  <div class="">
                    <div
                      class="d-flex justify-content-between date-amount"
                      v-for="(item, index) in paymode"
                      :key="index"
                    >
                      <h6
                        class="breakedown-content date-selected"
                        v-if="item.date && item.amount"
                      >
                        {{ item.date | moment }}
                      </h6>
                      <h6
                        class="breakedown-content amount-selected"
                        v-if="item.date && item.amount"
                      >
                        &#8358;{{ Number(item.amount).toLocaleString() }}
                      </h6>
                    </div>

                    <div
                      class="d-flex justify-content-between date-amount"
                      v-if="totalpayment"
                    >
                      <h6 class="total-content date-selected">Total payable</h6>
                      <h6 class="total-content amount-selected">
                        &#8358;{{ Number(totalpayment).toLocaleString() }}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group d-none">
                <label for="bvn">BVN</label>
                <input
                  type="number"
                  class="form-control"
                  id="bvn"
                  name="bvn"
                  v-model="bvn"
                  maxlength="11"
                  placeholder="Enter your BVN"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                />
                <div
                  class="bvnname"
                  v-if="bvndata.FirstName && bvndata.Surname"
                >
                  <h6>{{ bvndata.FirstName }} {{ bvndata.Surname }}</h6>
                </div>
                <small class="requireBvn" @click="reveal = !reveal"
                  >Why we require your BVN?</small
                >
                <div class="whyBvn" v-if="reveal">
                  <p>
                    We Use Licensed Credit Bureaus for Eligibility Checks.
                    Shelta Doesn't Retain Your Banking Information. Your Profile
                    Details Are Securely Encrypted Using Industry-Standard
                    End-to-End Encryption. Providing your BVN helps us ensure
                    the accuracy of your application and enhances the security
                    of your information.
                  </p>
                </div>
              </div>
            </form>

            <div class="continueBtn" v-if="rent.rentFinancingObject.guarantor_id === null || rent.rentFinancingObject.guarantor_id === ''">
              <button
                data-toggle="modal"
                data-target="#selectGuarantor"
                class="btn"
                :disabled="mode === ''"
              >
                Add Guarantor
              </button>
            </div>

            <div class="continueBtn" v-else>
              <button
                data-toggle="modal"
                data-target="#selectGuarantor"
                class="btn change-guarantor-btn"
              >
                Change Guarantor
              </button>
            </div>
            <div class="or-text" v-if="(rent.rentFinancingObject.guarantor_id != null)">
              <button class="btn">OR</button>
            </div>
            <div class="continueBtn" v-if="(rent.rentFinancingObject.guarantor_id != null)">
              <button
                data-toggle="modal"
                data-target="#bvnmodal"
                class="btn"
                :disabled="mode === ''"
              >
                Verify BVN
              </button>
            </div>
            <div class="continueBtn d-none" v-if="rent.rentFinancingObject.guarantor_id != null || rent.rentFinancingObject.guarantor_id != ''">
              <button
                data-toggle="modal"
                data-target="#bvnmodal"
                class="btn"
                :disabled="mode === ''"
              >
                Verify BVN
              </button>
            </div>

            <div class="continueBtn d-none">
              <button
                v-if="!showSubmit"
                class="btn"
                @click="verifyBvn"
                :disabled="bvn === '' || (bvn.length < 11 || bvnLoader) || ( activemode === '')"
              >
                Validate BVN
                <i
                  class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                  v-if="bvnLoader"
                ></i>
              </button>
              <button
                @click.prevent="applyForRentFinancing"
                v-if="showSubmit"
                class="btn"
                data-toggle="modal"
                data-target="#rentsuccess"
                :disabled="loading"
              >
                Apply
                <i
                  class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                  v-if="loading"
                ></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal to select or add new guarantor starts -->
    <div
      tabindex="-1"
      class="modal fade"
      id="selectGuarantor"
      role="dialog"
      aria-labelledby="selectGuarantor"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style="margin-bottom: 30px">
          <!-- <div class="modal-header"></div> -->
          <div class="top-header-guarantor">
            <div class="header-guarantor">
              <h5>Guarantor</h5>
              <p>Add or select from our smart guarantors.</p>
            </div>
            <div>
              <small class="close" @click="activeG = ''" data-dismiss="modal"
                >X</small
              >
            </div>
          </div>

          <div class="guarantor-boby">
            <div class="form-group">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span
                    class="input-group-text"
                    style="background: none; border: 1px solid #D0D5DD;"
                    ><i class="fa fa-search"></i
                  ></span>
                </div>
                <input
                  style="border-left: none; padding-left: 0 !important"
                  @keyup="filterSmartGuarantor(searchsg)"
                  :disabled="loadingFilter"
                  type="text"
                  class="form-control"
                  name="filter"
                  id="filter"
                  v-model="searchsg"
                  placeholder="Search for a smart  by name"
                />
                <!-- <div class="input-group-append">
                  <span class="input-group-text" style="cursor: pointer"
                    >Search</span
                  >
                </div> -->
              </div>
            </div>
            <div>
              <div class="guarantor" id="scrollbar-target">
                <div
                  @click="
                    activeG = index;
                    guarantorItem(item);
                  "
                  class="guarantor-holder"
                  :class="[activeG === index ? 'activeGuarantor' : '']"
                  v-for="(item, index) in userGuarantors"
                  :key="index"
                >
                  <small :class="[activeG === index ? 'activeName' : '']">{{
                    item.initials
                  }}</small>
                  <div
                    class="d-flex justify-content-between"
                    style="width: 100%"
                  >
                    <div>
                      <h5>{{ item.name }}</h5>
                      <h6>{{ item.phone }}</h6>
                      <h4>{{ item.address }}</h4>
                    </div>
                    <div
                      class="select-holder"
                      :class="[activeG === index ? 'activeCheck' : '']"
                    >
                      <i v-if="index === activeG" class="fa fa-check"></i>
                    </div>
                  </div>
                </div>

                <div
                  @click="
                    activeG2 = index;
                    smartguarantorItem(item);
                  "
                  class="guarantor-holder"
                  :class="[activeG2 === index ? 'activeGuarantor' : '']"
                  v-for="(item, index) in guarantorsSmart.slice(0, 2)"
                  :key="'A' + index"
                >
                  <small :class="[activeG2 === index ? 'activeName' : '']">{{
                    item.initials
                  }}</small>
                  <div
                    class="d-flex justify-content-between"
                    style="width: 100%"
                  >
                    <div>
                      <h5>{{ item.name }}</h5>
                      <h6>Smart Guarantor</h6>
                      <!-- <h6>{{ item.phone }}</h6>
                      <h4>{{ item.address }}</h4> -->
                      <div class="rating">
                        <!-- <img
                          style="width: 28px"
                          src="../assets/icons/star3.svg"
                          alt="raings"
                        /> -->
                        <img src="../assets/icons/star2.svg" alt="raings" />
                        <img src="../assets/icons/star2.svg" alt="raings" />
                        <img src="../assets/icons/star2.svg" alt="raings" />
                        <img src="../assets/icons/star2.svg" alt="raings" />
                        <img src="../assets/icons/star2.svg" alt="raings" />
                      </div>
                    </div>
                    <div
                      class="select-holder"
                      :class="[activeG2 === index ? 'activeCheck' : '']"
                    >
                      <i v-if="index === activeG2" class="fa fa-check"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="add-guarantor">
            <p
              @click="closeG()"
              data-toggle="modal"
              data-target="#newguarantor"
            >
              Add your guarantor
            </p>
          </div>
          <div class="gurantor-btn">
            <button
              type="button"
              class="btn cancel"
              @click="activeG = ''"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn continue"
              data-dismiss="modal"
              :disabled="guarantorId === ''"
              @click="openBvn()"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal to select or add new guarantor starts -->

    <!-- Modal to add new guarantor starts -->
    <div
      tabindex="-1"
      class="modal fade"
      id="newguarantor"
      role="dialog"
      aria-labelledby="newguarantor"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style="margin-bottom: 30px">
          <div class="top-header-guarantor">
            <div class="header-guarantor">
              <h5>Add new guarantor</h5>
              <p>Provide the details of your guarantor.</p>
            </div>
            <div>
              <small class="close" data-dismiss="modal">X</small>
            </div>
          </div>
          <div class="bvn-input">
            <div class="form-group">
              <label for="fname">Full name</label>
              <input
                style="text-transform: capitalize;"
                class="form-control"
                type="text"
                name="fname"
                id="fname"
                placeholder="Enter full name"
                v-model="guarantorfullname"
              />
            </div>
            <div class="form-group">
              <label for="email">Email address</label>
              <input
                style="text-transform: lowercase;"
                class="form-control"
                type="email"
                name="email"
                id="email"
                placeholder="Enter email address"
                v-model="guarantoremail"
              />
            </div>
            <div class="form-group">
              <label for="address">Home address</label>
              <input
                style="text-transform: capitalize;"
                class="form-control"
                type="text"
                name="address"
                id="address"
                placeholder="Enter home address"
                v-model="guarantoraddress"
              />
            </div>
            <div class="form-group">
              <label for="phone">Phone number</label>
              <input
                class="form-control"
                type="number"
                name="phone"
                id="phone"
                placeholder="Enter phone number"
                v-model="guarantorphone"
              />
            </div>
            <div class="form-group">
              <label for="phone">Relationship</label>
              <input
                class="form-control"
                type="text"
                name="relationship"
                id="relationship"
                placeholder="Enter relationshp"
                v-model="guarantorrelationship"
              />
            </div>
          </div>

          <div class="gurantor-btn">
            <button type="button" class="btn cancel" data-dismiss="modal">
              Cancel
            </button>
            <button
              :disabled="gLoader"
              type="button"
              class="btn continue"
              @click="createGuarantor()"
            >
              Submit
              <i
                class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                v-if="gLoader"
              ></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal to add new guarantor ends -->

    <!-- BVN modal starts -->
    <div
      tabindex="-1"
      class="modal fade"
      id="bvnmodal"
      role="dialog"
      aria-labelledby="bvnmodal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style="margin-bottom: 30px">
          <div class="top-header-guarantor">
            <div class="header-guarantor">
              <h5>Verify BVN</h5>
              <p>Your BVN helps verify your identity.</p>
            </div>
            <div>
              <small
                class="close"
                @click="
                  bvn = '';
                  activeG = '';
                "
                data-dismiss="modal"
                >X</small
              >
            </div>
          </div>
          <div class="bvn-input">
            <div class="mb-3">
              <input
                class="form-control"
                type="text"
                name="bvn2"
                id="bvn2"
                v-model="bvn"
                maxlength="11"
                placeholder="Enter your BVN"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              />
              <div
                class="bvnname"
                v-if="bvndata.FirstName && bvndata.Surname"
              >
                <h6>{{ bvndata.FirstName }} {{ bvndata.Surname }}</h6>
              </div>
            </div>
            <small @click="reveal = !reveal">Why we require your BVN?</small>
            <div class="whyBvn" v-if="reveal">
              <p>
                We Use Licensed Credit Bureaus for Eligibility Checks. Shelta
                Doesn't Retain Your Banking Information. Your Profile Details
                Are Securely Encrypted Using Industry-Standard End-to-End
                Encryption. Providing your BVN helps us ensure the accuracy of
                your application and enhances the security of your information.
              </p>
            </div>
          </div>

          <div class="gurantor-btn">
            <button
              @click="
                bvn = '';
                activeG = '';
              "
              type="button"
              class="btn cancel"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              v-if="!showSubmit"
              @click="verifyBvn"
              :disabled="bvn == '' || bvn.length < 11 || bvnLoader"
              type="button"
              class="btn continue"
            >
              Verify
              <i
                class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                v-if="bvnLoader"
              ></i>
            </button>
            <button
              @click.prevent="applyForRentFinancing"
              v-if="showSubmit"
              class="btn continue"
              data-toggle="modal"
              data-target="#rentsuccess"
              :disabled="loading || bvn == '' || bvn.length < 11"
            >
              Apply
              <i
                class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                v-if="loading"
              ></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- BVN modal ends -->

    <!-- Success modal begins -->
    <div
      tabindex="-1"
      class="modal fade"
      id="rentsuccess"
      role="dialog"
      aria-labelledby="sub"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style="margin-bottom: 30px">
          <!-- <div class="modal-header"></div> -->
          <div class="modal-body confirmation-body">
            <img src="../assets/images/green-check-round.svg" alt="" />
            <h5>Rent Application successfully</h5>
            <p>
              Congratulations! Your Rent Application has been received. Approval
              notification will be sent within 72 hours (3 days). Expect payment
              invoice via email upon approval.
            </p>
          </div>
          <div class="modal-footer modal-button-confirm">
            <button
              type="button"
              class="btn okay"
              data-dismiss="modal"
              @click.prevent="routeTo"
            >
              Okay
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Success modal ends -->
  </div>
</template>
<script>
import PageLoader from "./PageLoader.vue";
import { Service } from "../store/service";
const Api = new Service();
import $ from "jquery";
import moment from "moment";
// import Vue from "vue";
export default {
  name: "ApplyRentFinancing2",
  // props: {
  //   properties
  // },

  components: {
    PageLoader
  },
  data() {
    return {
      loading: false,
      reveal: false,
      diableBvnBtn: false,
      loadingFilter: false,
      bvnLoader: false,
      showSubmit: false,
      hideDiv: false,
      gLoader: false,
      filterednow: false,
      price: "",
      amount: "",
      amount2: "",
      bvn: "",
      rent: "",
      rent: {
        rentFinancingObject: {
          guarantor_id: "",
        }
      },
      ticket: "",
      bvndata: {},
      activemode: "",
      activeMode: "",
      mode: "",
      paymode: "",
      paymentshedules: [],
      pin: "",
      user: "",
      searchsg: "",
      totalpayment: "",
      propDetails: "",
      userGuarantors: [],
      guarantorsSmart: [],
      guarantorId: "",
      guarantorType: "",
      activeG: "",
      activeG2: "",
      guarantoraddress: "",
      guarantorrelationship: "",
      guarantorphone: "",
      guarantoremail: "",
      guarantorfullname: "",
    };
  },
  watch: {
    // bvn: function(newValue) {
    //   if (newValue.length < 11) {
    //     this.showSubmit = false;
    //   }
    // },
    searchsg: function(newValue) {
      // console.log("new value", newValue);
      if (newValue.length == 0 || newValue == "") {
        this.filterednow = false;
        // console.log("Hello")
        this.guarantorsSmart = [];
        // this.smartGuarantorsFiltered();
        // this.smartGuarantors();
      }
      // if (this.filterednow.length == 0) {
      //   this.filterednow = []
      //   this.smartGuarantorsFiltered();
      // }
    },
    // bvn: function(newValue) {
    //   if (newValue === "") {
    //     this.showSubmit = false;
    //     console.log('showSubit1', this.showSubmit);
    //   } else {
    //     this.showSubmit = true;
    //     console.log('showSubit2', this.showSubmit);
    //   }
    // }
  },
  //   created() {
  //     this.$root.$refs.PropertyManagementAgreement = this === "Property Management Agreement";
  //   },
  // watch: {
  //   price: function(newValue) {
  //     console.log("New value", newValue);
  //     const result = newValue
  //       .replace(/\D/g, "")
  //       .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //     Vue.nextTick(() => (this.price = result));
  //     console.log("Price", this.price);
  //     this.amount = this.price.split(",").join("");
  //   },
  //   immediate: true
  // },
  mounted() {
    this.creditBureauCheck();
    this.pin = this.$route.params.pin;
    this.fetchPropertyDetails();
    this.user = JSON.parse(localStorage.getItem("shelta-user"));
    this.smartGuarantors();
  },
  methods: {
    routeTo() {
      // renteddetails
      Api.pushPropertyDetails(this.propDetails);

      return this.$router.push({
        // path: `/dashboard/rented`
        path: `/dashboard/renteddetails`
      });
    },
    active(data, mode) {
      // console.log("mode", mode);
      this.activeMode = data;
      this.mode = mode;
      this.paymode = mode.schedule;
      // this.calculations();
    },
    closeG() {
      $("#selectGuarantor")
        .removeClass("fade")
        .modal("hide");
      $("#newguarantor")
        .addClass("fade")
        .modal("show");
    },
    openBvn() {
      $("#selectGuarantor")
        .removeClass("fade")
        .modal("hide");
      $("#bvnmodal")
        .addClass("fade")
        .modal("show");
    },
    async creditBureauCheck() {
      try {
        await Api.getRequest(`getcreditbeareauticket`)
          .then(res => {
            if (res.data.success === "login successful") {
              this.ticket = res.data.ticket;
              // console.log("credit ticket on init", this.ticket);
            }
          })
          .catch(err => {
            console.log("err", err);
            this.diableBvnBtn = false;
          });
      } finally {
        // console.log(closed);
      }
    },
    async createGuarantor() {
      this.gLoader = true;
      if (
        this.guarantorfullname === "" ||
        this.guarantoremail === "" ||
        this.guarantoraddress === "" ||
        this.guarantorphone === "" ||
        this.guarantorrelationship === ""
      ) {
        this.gLoader = false;
        return this.$store.commit("setApiFailed", "All fields are rquired");
      }
      if ((this.guarantorphone).length < 11) {
        this.gLoader = false;
        return this.$store.commit("setApiFailed", "Phone number less than 11 digits");
      }
      const validRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!validRegex.test(this.guarantoremail)) {
        this.gLoader = false;
        return this.$store.commit("setApiFailed", "Invalid Email address!");
      }
      try {
        await Api.postRequest(`addnormalguarantor`, {
          address: this.guarantoraddress,
          relationship: this.guarantorrelationship,
          phone: this.guarantorphone,
          email: this.guarantoremail,
          fullname: this.guarantorfullname
        })
          .then(res => {
            // console.log("Guarantor response", res);
            this.gLoader = false;
            if (res.data.success) {
              this.guarantorId = res.data.gurantor.id;
              this.guarantorType = "ng";
              this.smartGuarantors();
              this.fetchPropertyDetails();
              $("#newguarantor")
                .removeClass("fade")
                .modal("hide");
              $("#bvnmodal")
                .addClass("fade")
                .modal("show");
              return this.$store.commit("setApiSuccess", "Guarantor added");
            }
            if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            this.gLoader = false;
            console.log(err);
          });
      } finally {
        // console.log("closed");
      }
    },
    async applyForRentFinancing() {
      this.loading = true;
      // console.log("user id", this.user.id);
      try {
        await Api.postRequest(`applyforrentfinancingsimplified`, {
          userid: this.user.id,
          guarantor_type: this.guarantorType,
          guarantor_id: this.guarantorId,
          propertyid: this.rent.id,
          modeofpaymentid: this.mode.id,
          bvn: parseInt(this.bvn)
        })
          .then(res => {
            this.loading = false;
            // console.log("details of pro", res);
            if (res.data.success) {
              this.propDetails = res.data.property;
              $("#bvnmodal")
                .removeClass("fade")
                .modal("hide");
              $("#rentsuccess")
                .addClass("fade")
                .modal("show");
            }
            if (res.data.error) {
              this.showSubmit = false;
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            console.log("err", err);
            this.showSubmit = false;
            this.loading = false;
          });
      } finally {
        // console.log("closed");
      }
    },
    async verifyBvn() {
      this.bvnLoader = true;
      const bvn = new RegExp("^[0-9]*$");
      this.diableBvnBtn = true;
      this.loader = true;
      if (bvn.test(this.bvn) == false) {
        this.loader = false;
        this.diableBvnBtn = false;
        this.$store.commit("setApiFailed", "BVN must be in numeric form");
        // alert("Only Numeric Ticket ID is allowed.");
      } else {
        if (this.ticket === "" || this.bvn === "" || this.bvn.length < 11) {
          // console.log("No data");
          this.diableBvnBtn = true;
          // this.creditBureauCheck();
          // this.confirmcreditcheck();
        } else {
          try {
            await Api.postRequest(`creditbeareuverifyusingbvn`, {
              bvn: this.bvn,
              ticket: this.ticket
            })
              .then(res => {
                this.bvnLoader = false;
                // console.log("bvn verification:", res); 
                if (res.status == 200) {
                  this.showSubmit = true;
                  this.bvndata = res.data.data[0].MatchedConsumer[0];
                  const fname = this.bvndata.FirstName;
                  const sname = this.bvndata.Surname;
                  const accountnumber = this.bvndata.AccountNo;
                  const consumerid = this.bvndata.ConsumerID;
                  // const fname = this.bvndata.FirstName;

                  if (
                    fname === null ||
                    (sname === null &&
                      accountnumber === null &&
                      consumerid === null)
                  ) {
                    this.showSubmit = false;
                    this.$store.commit(
                      "setApiFailed",
                      "BVN does not exist or is Invalid"
                    );
                    this.diableBvnBtn = false;
                    // this.checkeligibility = false;
                    // this.diableBvnInput = false;
                  } else {
                    // this.diableBvnInput = true;
                    // this.checkeligibility = true;
                    // this.eligibilityCheck();
                    this.diableBvnBtn = false;
                  }
                }
              })
              .catch(err => {
                console.log("err", err);
                // this.diableBvnInput = false;
                // this.checkeligibility = false;
                this.diableBvnBtn = false;
                this.bvnLoader = false;
                this.showSubmit = false;
              });
          } finally {
            // console.log(closed);
          }
        }
      }
    },
    guarantorItem(data) {
      this.activeG2 = "";
      this.guarantorId = data.id;
      this.guarantorType = data.type;
    },
    smartguarantorItem(data) {
      this.activeG = "";
      this.guarantorId = data.id;
      this.guarantorType = data.type;
    },
    filterSmartGuarantor(string) {
      this.filterednow = true;
      const regexp = new RegExp(string, "i");
      const filtered = this.guarantorsSmart.filter(x => regexp.test(x.name));
      // console.log("Filter", filtered);
      this.guarantorsSmart = [];
      this.guarantorsSmart = filtered;
      // this.smartGuarantors();
      if (this.guarantorsSmart.length == 0 || this.searchsg === "") {
        this.guarantorsSmart = [];
        this.smartGuarantorsFiltered();
      }
    },
    async smartGuarantorsFiltered() {
      // if (this.searchsg === "") {
      //   this.filterednow = false;
      // } else {
      //   this.filterednow = true;
      // }
      this.loadingFilter = true;
      try {
        await Api.getRequest(
          `fetchguarantorswhilerentfinancing/${this.user.id}`
        )
          .then(res => {
            // console.log("guarantors smart", res);
            this.loadingFilter = false;
            if (res.data.success) {
              const guarantors = res.data.sg;
              guarantors.forEach(element => {
                const y = element.name.split(" ");
                const iniName = element.name.charAt(0);
                const iniLname = y[y.length - 1].charAt(0);
                const x = iniName + iniLname;
                element.initials = x;
                return this.guarantorsSmart.push(element);
              });
            }
            if (res.data.error) {
              this.loadingFilter = false;
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            this.loadingFilter = false;
            console.log(err);
          });
      } finally {
        // console.log("closed");
      }
    },
    async smartGuarantors() {
      // if (this.searchsg === "") {
      //   this.filterednow = false;
      // } else {
      //   this.filterednow = true;
      // }
      try {
        await Api.getRequest(
          `fetchguarantorswhilerentfinancing/${this.user.id}`
        )
          .then(res => {
            // console.log("guarantors smart", res);
            if (res.data.success) {
              const guarantors2 = res.data.ng;
              guarantors2.forEach(element => {
                const y = element.name.split(" ");
                const iniName = element.name.charAt(0);
                const iniLname = y[y.length - 1].charAt(0);
                const x = iniName + iniLname;
                element.initials = x;
                this.userGuarantors.push(element);
              });
              const guarantors = res.data.sg;
              guarantors.forEach(element => {
                const y = element.name.split(" ");
                const iniName = element.name.charAt(0);
                const iniLname = y[y.length - 1].charAt(0);
                const x = iniName + iniLname;
                element.initials = x;
                this.guarantorsSmart.push(element);
              });
            }
            if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            console.log(err);
          });
      } finally {
        // console.log("closed");
      }
    },
    async fetchPropertyDetails() {
      this.hideDiv = true;
      // this.pageLoader = true;
      try {
        // const pin = this.propertyDetails.pin;
        // const pin = 2308287531;
        await Api.getRequest(`paymentsummaryforrentfinance/${this.pin}`)
          .then(res => {
            this.hideDiv = false;
            if (res.data.success) {
              this.pageLoader = false;
              // console.log("details of property", res);
              this.rent = res.data.property;
              this.thePrice();
              // this.modeId = res.data.property.tenantmodeofpaymentid;
              this.paymode = res.data.property.tenantmodeofpaymentid;
              this.schedules = res.data.property.tenantmodeofpayments;
              // this.calculations();
            }
            // console.log("shedule", this.schedules);
          })
          .catch(err => {
            console.log(err);
            // this.pageLoader = false;
            this.hideDiv = true;
            // this.$store.commit("setApiFailed", res.data.error);
          });
      } finally {
        // console.log("closed");
      }
    },
    thePrice() {
      this.price = this.rent.price;
      let x = this.price;
      x.toString();
      const pattern = /(-?\d+)(\d{3})/;
      while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
      this.amount2 = x;
      // console.log("XXX", x);
      this.amount = this.price.split(",").join("");
      // console.log("Amount", this.amount);
    }
  },
  filters: {
    moment: function(date) {
      return moment(date).format("MMMM Do YYYY");
    }
    // moment: function(date) {
    //   return moment(date).format("MMMM DD YYYY, h:mm:ss a");
    // },
  },
  immediate: true
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$white: #ffffff;
$yellow: #ffb100;
$black: #000000;
$shelta-black: #000a2f;
$shelta-gray: #555c74;
$shelta-brown: #553b00;
$shelta-warning: #fff7e6;
$shelta-off-white: #f9fafb;
$shelta-grey-2: #57595a;
$dline: #dddddd;
$off-grey: #f9f9f9;
$disablled-input: #f8fafc;
$e7: #e7e7e7;

@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}

@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}

@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
}

@font-face {
  font-family: "Lato";
  src: local("Lato-Regular"),
    url("../assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Lato-light";
  src: local("Lato-Light"),
    url("../assets/fonts/Lato/Lato-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Lato-bold-Italic";
  src: local("Lato-BlackItalic"),
    url("../assets/fonts/Lato/Lato-BlackItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Lato-thin-Italic";
  src: local("Lato-ThinItalic"),
    url("../assets/fonts/Lato/Lato-ThinItalic.ttf") format("truetype");
}

// Guarantor modal starts
.top-header-guarantor {
  display: flex;
  justify-content: space-between;
  padding: 24px 24px 0px;
  border-bottom: 1px solid #e4e7ec;
}
.header-guarantor {
  h5 {
    color: #2b3352;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 4px;
    text-align: left;
  }
  p {
    color: #555c74;
    font-family: Lato;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }
}
.close {
  font-size: 18px;
  border-radius: 8px;
  color: #555c74;
  padding: 10px;
  background: #f5f5f5;
  cursor: pointer;
  // width: 24px;
  // height: 24px;
}
.guarantor {
  overflow-y: scroll;
  max-height: 260px;
  overflow-x: hidden;
}
.guarantor-boby {
  padding: 20px 24px;
}
.guarantor-holder {
  cursor: pointer;
  margin-bottom: 12px;
  border-radius: 12px;
  border: 2px solid #e4e7ec;
  display: flex;
  padding: 16px;
  small {
    margin-bottom: 0;
    width: 40px;
    height: 40px;
    background: #f0f6ff;
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: $primary;
    border-radius: 50px;
    padding: 11px;
    margin-right: 10px;
    text-transform: uppercase;
  }
  h5,
  h6,
  h4 {
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    margin-bottom: 4px;
  }
  h5 {
    color: #2b3352;
  }
  h6,
  h4 {
    color: #555c74;
  }
}
.rating {
  text-align: left;
  img {
    cursor: pointer;
    padding-right: 8px;
  }
}
.select-holder {
  width: 16px;
  height: 16px;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  i {
    font-size: 10px;
    display: flex;
    padding: 2.4px;
    color: $white;
  }
}

.add-guarantor {
  padding: 0 24px;
  p {
    cursor: pointer;
    color: $primary;
    text-align: right;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
  }
}
.gurantor-btn {
  display: flex;
  padding: 32px 24px 24px;
  gap: 10px;
  button {
    width: 100%;
    height: 48px;
    padding: 0px 18px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    border-radius: 8px;
  }
  .cancel {
    color: #2b3352;
    border: 1px solid #d0d5dd;
  }
  .continue {
    background: $primary;
    color: $white;
  }
}
.bvn-input {
  padding: 20px 24px;
  input {
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    outline: 0;
    box-shadow: none;
  }
  small {
    color: $primary;
    font-family: Lato;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    display: flex;
    cursor: pointer;
    margin-bottom: 6px;
  }
}
.activeGuarantor {
  border: 2px $primary;
  background: #dde4fc;
}
.activeName {
  background: $primary !important;
  color: $white !important;
}
.activeCheck {
  background: $primary;
}

#scrollbar-target::-webkit-scrollbar {
  display: none !important;
}

#scrollbar-target::-webkit-scrollbar {
  width: 5px;
}

/* Track */
#scrollbar-target::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
#scrollbar-target::-webkit-scrollbar-thumb {
  background: $primary;
  border-radius: 10px;
}

/* Handle on hover */
#scrollbar-target::-webkit-scrollbar-thumb:hover {
  background: $primary;
}

::-webkit-input-placeholder {
  /* Edge */
  color: #ccced5;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ccced5;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

::placeholder {
  color: #ccced5;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
// Guarantor modal ends

.holder {
  border-radius: 20px;
  background: $white;
  padding: 40px 24px;
  margin: 40px;

  @media only screen and (max-width: 599px) {
    margin: 40px 15px;
  }
}

.financing-text {
  color: $shelta-black;
  font-family: Lato;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  text-align: left;
  margin-bottom: 0;
}

.parent {
  padding-top: 40px;
  // .lh {
  //     padding-left: 0;
  // }
  // .rh {
  //     padding-right: 0;
  // }
}

.left-side {
  h6 {
    color: $shelta-black;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    text-align: left;
  }

  p {
    color: $shelta-gray;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-align: left;
  }
}

.radio-label {
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: $shelta-black;
  display: flex;
  box-shadow: none;
  outline: 0;
}

.form-group > label {
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  display: flex;
  color: $shelta-black;
  margin-bottom: 4px;
}

input[type="radio"] {
  // transform:scale(1.5);
  width: 20px;
  height: 20px;
}

.naira {
  left: 0;
  bottom: 10%;
  position: absolute;
  margin: 0px 10px;
  padding: 2px 0;
}

.amountNaira {
  position: relative;

  input {
    padding-left: 24px;
  }
}

.mode-label {
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  display: flex;
  color: $shelta-black;
  margin-bottom: 6px;
}

input[type="select"],
input[type="text"],
input[type="number"],
input[type="email"],
input[type="date"],
input[type="password"] {
  height: 48px;
  // border: 0.7px solid #e5e5e5;
  border-radius: 5px;
  border: 1px solid $e7;
}

input[type="text"]:disabled {
  background: $disablled-input;
}

.form-group {
  margin-bottom: 32px;

  select,
  input {
    box-shadow: none;
    outline: 0;
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: $shelta-black;
    // text-transform: capitalize;
  }

  textarea {
    // text-transform: lowercase;
    box-shadow: none;
    outline: 0;
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: $shelta-black;
  }
}

.activeMode {
  border: 1px solid $primary !important;
  // box-shadow: 2px 2px 4px rgba(59, 13, 158, 0.2);
}

.rate:disabled {
  box-shadow: none;
  cursor: not-allowed;
}

.rate {
  // width: 100%;
  min-width: 100%;
  cursor: pointer;
  background: $off-grey;
  border-radius: 4px;
  padding: 8px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);

  p,
  h6 {
    color: $shelta-black;
    // font-family: Satoshi;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    white-space: nowrap;
    // line-height: 150%;
  }

  p {
    margin-bottom: 0px;
    font-weight: normal;
  }

  h6 {
    margin-bottom: 0;
    // font-size: 17px;
    font-weight: 500;
  }

  @media only screen and (max-width: 599px) {
    margin-bottom: 16px;
  }
}

.breakdown {
  border-radius: 6px;
  border: 0.5px solid $e7;
  padding: 16px;

  h4 {
    padding: 16px 0;
    color: $shelta-gray;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    text-align: left;
    border-bottom: 1px solid $e7;
    margin-bottom: 0;
  }
}

.breakedown-content {
  color: $shelta-gray;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  // border-bottom: 1px solid $e7;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-bottom: 0;
}

.total-content {
  color: $shelta-black;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  // border-bottom: 1px solid $e7;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-bottom: 0;
}

// .breakedown-content:last-child {
//   border-bottom: none;
// }
.date-selected {
  text-align: left;
}

.amount-selected {
  text-align: right;
}

.date-amount {
  border-bottom: 1px solid #e7e7e7;
}

.date-amount:last-child {
  border-bottom: 0;
}

.total_breakdown {
  h6 {
    color: $shelta-black;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    padding-top: 16px;
    padding-bottom: 16px;
    margin-bottom: 0;
  }
}

.requireBvn {
  color: $primary;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  text-align: left;
  display: flex;
  padding: 4px 0 8px;
  cursor: pointer;
}

.whyBvn {
  padding: 16px 8px;
  border-radius: 8px;
  background: $off-grey;

  p {
    color: $shelta-gray;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-align: left;
  }
}

.bvnname {
  height: 48px;
  border-radius: 5px;
  border: 1px solid $e7;
  margin-top: 10px;

  h6 {
    color: $shelta-gray;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    // line-height: 150%;
    padding-top: 16px;
    padding-bottom: 16px;
    margin-bottom: 0;
    text-align: left;
    padding-right: 15px;
    padding-left: 15px;
  }
}

.continueBtn {
  text-align: right;

  button {
    background: $primary;
    color: $white;
    width: 178px;
    height: 48px;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    outline: 0;
    box-shadow: none;
  }

  button:hover {
    background: #032086;
  }
}
.change-guarantor-btn {
  background: none !important;
  color: $primary !important;
  // float: left !important;
}

.or-text {
  text-align: right;
  button {
    font-family: Lato;
    color: $black;
    font-size: 16px;
  }
}

@media only screen and (max-width: 599px) {
  .continueBtn {
    text-align: center;
  }
  .or-text {
    text-align: center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  // .change-guarantor-btn {
  //   float: right;
  // }
  .continueBtn {
    text-align: center;
  }
  .or-text {
    text-align: center;
  }
}

@media screen and (min-width: 1024px) {
  /* CSS rules for large screens */
  .or-text {
    padding-right: 60px;
  }
}

// @media only screen and (max-width: 768px) {
//   .change-guarantor-btn {
//     float: right;
//   }
// }

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

// Confirmation modal
.confirmation-body {
  padding: 24px 40px 40px;

  @media only screen and (max-width: 599px) {
    .continueBtn {
      text-align: center;
    }

    padding: 24px 20px 40px;
  }

  img {
    padding-top: 8px;
    width: 64px;
  }

  h5 {
    padding-top: 24px;
    color: $shelta-black;
    text-align: center;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    text-transform: capitalize;
    margin-bottom: 8px;
  }

  p {
    color: $shelta-gray;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 0;
  }
}

.modal-button-confirm {
  border-top: none;
  margin: 0 40px 40px;

  button:hover {
    background: #0124a5;
  }

  button {
    background: $primary;
    color: $white;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    height: 48px;
    width: 100%;

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  @media only screen and (max-width: 599px) {
    margin: 0 20px 40px;
    padding: 0;
  }
}

// Confirmation modal
</style>
